
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import ParticipantsList from '@/components/ParticipantsList.vue'
import InfoModal from '@/components/InfoModal.vue'
import { ParticipantObj } from '@/store/participants/types'

export default defineComponent({
  name: 'Participants',
  components: {
    ParticipantsList,
    InfoModal
  },
  data () {
    return {
      programsArr: [] as Array<string>,
      filter: '',
      participantsArr: [] as Array<ParticipantObj>
    }
  },
  computed: {
    ...mapGetters({
      participants: 'getParticipantsData',
      loaded: 'getParticipantsLoaded',
      message: 'getParticipantsMessage'
    })
  },
  watch: {
    participants () {
      this.programsList()
    }
  },
  mounted () {
    this.$store.dispatch('getParticipantsData', { type: 'retrieveAll' })
  },
  methods: {
    pluralizeName: function (length:number, value:string): string {
      if (length === 1) {
        if (value === 'Stories') {
          return 'Story' as string
        }
        return value.slice(0, -1) as string
      } else {
        return value
      }
    },
    programsList: function () {
      const newArr = [] as Array<string>
      this.participants.forEach((item:ParticipantObj) => {
        newArr.push(item.programsName[0])
      })
      this.programsArr = [...new Set(newArr)]
    },
    filterProgram: function (program: string) {
      this.filter = program
    }
  }
})
