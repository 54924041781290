import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-205ec7c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "identifiers-list list-grid" }
const _hoisted_2 = { class: "key" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.identifiersObject, (item) => {
      return (_openBlock(), _createElementBlock("li", {
        class: "identifier-item",
        key: item.identifier
      }, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(item.identifier), 1),
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data, (value) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "identifier-value",
              key: value
            }, _toDisplayString(value), 1))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}