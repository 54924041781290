import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0790d7f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participants-list list-grid grid one-up" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ParticipantListItem = _resolveComponent("ParticipantListItem")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participantsArr, (participant) => {
      return (_openBlock(), _createBlock(_component_ParticipantListItem, {
        key: participant.id,
        participantProp: (participant as any),
        filter: _ctx.filter
      }, null, 8, ["participantProp", "filter"]))
    }), 128))
  ]))
}