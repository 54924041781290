
import { defineComponent } from 'vue'

interface identifierObj {
  data: Array<string>,
  identifier: string
}

export default defineComponent({
  name: 'IdentifiersList',
  props: {
    identifierProp: Array
  },
  data () {
    return {
      identifiersObject: [] as Array<identifierObj>
    }
  },
  computed: {
    identifiersProp: function () {
      return this.identifierProp as Array<identifierObj>
    }
    // identifierObject: function ():any {
    //   const arrayHashmap = this.identifiersProp.reduce((obj:any, item:any) => {
    //     obj[item.identifier] ? obj[item.identifier].data.push(...item.data) : (obj[item.identifier] = { ...item })
    //     return obj
    //   }, {})
    //   const mergedArray = Object.values(arrayHashmap)
    //   return mergedArray
    // }
  },
  mounted () {
    if (this.identifiersObject.length === 0) {
      const arrayHashmap = this.identifiersProp.reduce((obj:any, item:any) => {
        obj[item.identifier] ? obj[item.identifier].data.push(...item.data) : (obj[item.identifier] = { ...item })
        return obj
      }, {})
      const mergedArray = Object.values(arrayHashmap) as Array<identifierObj>
      this.identifiersObject = mergedArray
    }
  }
})
