
import { defineComponent } from 'vue'
import ParticipantListItem from '@/components/ParticipantListItem.vue'
import { ParticipantObj } from '@/store/participants/types'

export default defineComponent({
  name: 'ParticipantsList',
  components: {
    ParticipantListItem
  },
  props: {
    participants: Array,
    loaded: Boolean,
    message: String,
    filter: String
  },
  computed: {
    participantsArr: function () {
      return this.participants as Array<ParticipantObj>
    }
  }
})
