
import { defineComponent, watchEffect, computed, ref } from 'vue'
import { extendRefernceItem, setItemClass, targetRoute } from '@/composables/referenceFunctions'
import IdentifiersList from '@/components/IdentifiersList.vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ParticipantObj } from '@/store/participants/types'

export default defineComponent({
  name: 'participantListItem',
  props: {
    participantProp: Object,
    filter: String
  },
  components: {
    IdentifiersList
  },
  setup (props): any {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const itemClass = ref('clipped')
    const participant = computed(() => props.participantProp as unknown as ParticipantObj)
    const favorites = computed(() => store.getters.getFavoritesParticipants as Array<string>)
    const nameArray = computed(() => participant.value.participantIdentifierTitles)
    const keyArray = computed(() => participant.value.participantIdentifierKeys)
    const formattedName = computed(() => participant.value.name.replace(/ /g, ''))
    const identifiersObject = computed(() => {
      const arr = [] as any
      const keyedArr = keyArray.value?.forEach((value, i) => {
        const arrItem = JSON.parse(JSON.stringify({ identifier: value as string, data: [nameArray.value[i]] as Array<string> }))
        arr.push(arrItem)
      })
      return arr
    })
    watchEffect(() => {
      itemClass.value = setItemClass(formattedName.value, route)
    })
    const onClick = (itemId:string) => {
      extendRefernceItem(itemId, route)
      router.push(targetRoute.value)
    }
    return {
      participant,
      formattedName,
      itemClass,
      favorites,
      identifiersObject,
      onClick
    }
  }
})
